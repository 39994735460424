import React, { SetStateAction, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Container, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ItemGrid from './item/item.tsx';
import { getBasaketItems, updateBasket, updateItem, updateOrder, insertToBasketStatus, checkOrderStatus, getCityForOrder, assignOrderToShipment } from '../APIs/APIs.tsx';
import { handlePrintLabel, getcurrentStage } from '../utility/utility.tsx';
import { Item } from '../typs.ts';


type FullScreenDialogItem = {
    id: number,
    open: boolean,
    order_no: string,
    order_id: number,
    handleClick: (id?: number) => void
};

/**
 * FullScreenDialog is a component that renders a dialog interface for managing and 
 * updating the status of items in an order. It allows users to scan or input SKUs 
 * to update item statuses and provides feedback based on the item’s current state.
 * 
 * Props:
 * @param {number} id - The unique identifier for the dialog.
 * @param {boolean} open - The state indicating whether the dialog is open or closed.
 * @param {number} order_no - The order number associated with the dialog.
 * @param {number} order_id - The order ID associated with the dialog.
 * @param {function} handleClick - A function to handle dialog state changes.
 * 
 * The dialog supports full-screen mode, adapts its layout on large screens, and 
 * displays a list of items with their SKUs and statuses. Users can interact with 
 * the dialog to manually update item statuses, view feedback, and save changes.
 */

/*************  ✨ Codeium Command ⭐  *************/
/**
 * The FullScreenDialog component renders a dialog interface for managing and
 * updating the status of items in an order. It allows users to scan or input SKUs
 * to update item statuses and provides feedback based on the item's current state.
 * 
 * The dialog supports full-screen mode, adapts its layout on large screens, and
 * displays a list of items with their SKUs and statuses. Users can interact with
 * the dialog to manually update item statuses, view feedback, and save changes.
 * 
 * Props:
 * @param {number} id - The unique identifier for the dialog.
 * @param {boolean} open - The state indicating whether the dialog is open or closed.
 * @param {number} order_no - The order number associated with the dialog.
 * @param {number} order_id - The order ID associated with the dialog.
 * @param {function} handleClick - A function to handle dialog state changes.
 */
/******  a70c730e-d914-47b2-8b1f-fd1734bc675f  *******/
export default function FullScreenDialog({ id, open, order_no, order_id, handleClick }: FullScreenDialogItem) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // Detect large screens (desktop)
    const [scannedItems, setScannedItems] = useState<{ sku: string; status: string }[]>([]);
    const [skuInput, setSkuInput] = useState<string>('');
    const [current_order_status, set_current_order_status] = useState<Number>()
    const [item, setItems] = useState<Item[] | undefined>();
    const warehouse = getcurrentStage()
    const matches: boolean = useMediaQuery('(min-width:300px)');
    const [sku, setSku] = useState<any>(undefined)
    const [updated_items, setupdatedItems] = useState<Item[] | undefined>();
    const [city, setCity] = useState<string>("")
    const user_id = localStorage.getItem('id')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSku(event.target.value as unknown as SetStateAction<string | undefined>)
    }

    /**
     * This function is used to update the status of items in the dialog screen.
     * @param value - the item to be updated
     * @param sku1 - the sku of the item
     * @param ref - the reference number of the item
     */
    const handleClick1 = async (value: Item, sku1: string, ref: number, trackChange: string) => {
        const array = sku1.split(",").map((item) => item.trim());
        let backgroundColor = '';
        let id = value.id

        let rightSKU = trackChange === 'global' ? sku1 : sku
        let newStatus: string;
        if (ref === 1) {
            newStatus = "Not Found";
            backgroundColor = "#F08080";
        } else if (array.includes(rightSKU)) {
            newStatus = "found";
            backgroundColor = "#ACE1AF";
        } else {
            newStatus = "sku-not-matched";
        }
        const updatedItem = { ...value, status: newStatus, background: backgroundColor };

        setItems((prevItems) => {
            return prevItems?.map((item) =>
                item.sku === updatedItem.sku && item.id === id ? updatedItem : item
            );
        });

        if (newStatus === "sku-not-matched") {
            return;
        }
        let stage = warehouse
        const toWhereToAnswer = stage === "center" ? "center_feedback" : stage === "store" ? "store_feedback" : stage === "procurement" ? "procumenet_feedback" : "quality_feedback"
        if (toWhereToAnswer === "quality_feedback" || "center_feedback")
            trackCompleteditems(warehouse)
        const state = newStatus === "found" ? "Yes" : newStatus === "Not Found" ? "No" : "sku-not-matched"

        const UpdatefromWhere = 'dialog'


        if (user_id) {
            let updatedItemsvalue = await updateItem(value.id, toWhereToAnswer, state, user_id, stage, value.basket_id, UpdatefromWhere)
            let itemsbelogorder = updatedItemsvalue?.filter((item: Item) => item.order_id === value.order_id)
            if (itemsbelogorder !== null)
                setupdatedItems(itemsbelogorder)
        }

    }

    /**
     * A function that calls the parent's handleClick function to close the dialog.
     * It is used to handle closing the dialog when the user clicks on the close button.
     */
    const handleControlDialog = () => {
        handleClick();
    };

    /**
     * Checks if all items in the `updated_items` state have a valid value
     * for the `center_feedback` or `quality_feedback` property, depending on
     * the `userType` parameter.
     *
     * @param {string} userType - The type of user, either 'center' or 'quality'.
     * @returns {boolean} True if all items have a valid value, false otherwise.
     */
    const trackCompleteditems = (userType: string) => {
        const isValid = updated_items?.every(item => {
            if (userType === 'center') {
                return item.center_feedback === 'Yes' || item.center_feedback === 'No';
            } else if (userType === 'quality') {
                return item.quality_feedback === 'Yes' || item.quality_feedback === 'No';
            }
            return false;
        });

        if (!isValid) {
            return false;
        }
        return true;
    };


    /**
     * Fetches the status of an order with the given order number.
     *
     * @param {string} order_no - The order number to fetch the status for.
     * @returns {Promise<string | null>} The status of the order, or null if an error occurred.
     */
    const getOrderStatus = async (order_no: string) => {
        console.log(order_no);
        try {
            const response = await checkOrderStatus(order_no);
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                console.log(response.data);
                return response.data[0].status;
            } else {
                throw new Error("No data found or invalid data format.");
            }
        } catch (error) {
            console.error("Error getting order status:", error);
            return null;
        }
    };


    useEffect(() => {

        const fetchDataAsync = async () => {
            if (id !== 0) {
                await fetchData();
            }
        };

        const checkOrderStatusAsync = async () => {
            if (id !== 0) {
                let status = await getOrderStatus(order_no);
                set_current_order_status(status);
            }
        };

        const getCityForOrderAsync = async () => {
            if (id !== 0) {
                let city = await getCityForOrder(order_no);
                if(city)
                setCity(city.data);
            }
        }
        fetchDataAsync();
        checkOrderStatusAsync();
        getCityForOrderAsync();
    }, [id]);

    const fetchData = async () => {
        let basketItems = await getBasaketItems(id, order_no.toString());
        setupdatedItems([]);
        const timer = setTimeout(() => {
            setItems(basketItems);
        }, 1000);
        return () => clearTimeout(timer);
    };


    useEffect(() => {
        setItems([])
        setupdatedItems([])

    }, [open])



    // const fetchData = async () => {
    //     let basketItems = await getBasaketItems(id, order_no.toString())
    //     setupdatedItems([])
    //     const timer = setTimeout(() => {
    //         setItems(basketItems)
    //     }, 1000);
    //     return () => clearTimeout(timer);
    // }

    // const checkOrderStatus = async (order_no: string) => {
    //    let response = await checkOrderStatus(order_no)
    //    return response.status;
    // }

    const handleSaveChanges = async () => {
        let status = "completed";
        let work_order_id = item?.[0]?.work_order_id;
        console.log(items)
        let basket_id = id;
        console.log('basket_id', basket_id)
        console.log('user_id', user_id)
        if (work_order_id && basket_id) {
            let basketUpdateResult: string | undefined | null;
            let orderUpdate: string | null;
            let insertToBasket: string | undefined | null;
            try {
                basketUpdateResult = await updateBasket(basket_id, 'available');
                insertToBasket = await insertToBasketStatus(updated_items)
                orderUpdate = await updateOrder([order_no], 'completed from quality', user_id)
                if (basketUpdateResult === "success") {
                    handleClick();
                    return "success";
                }
            } catch (error) {
                alert('something went wrong')
            }
        }
    }
    const handleSkuChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredSku = event.target.value.trim();
        setSkuInput(enteredSku);
        setSku(enteredSku)
        console.log('enteredSku', enteredSku)
        console.log('items', items)
        if (enteredSku) {
            const matchingItemIndex = items?.findIndex(item => {
                const skusArray = item.sku.split(",").map(sku => sku.trim());
                console.log(skusArray)
                console.log(skusArray.includes(enteredSku) && item.center_feedback === null)
                return skusArray.includes(enteredSku && (item.center_feedback === null || item.quality_feedback === null) ? enteredSku : enteredSku);
            });

            console.log('matchingItemIndex', matchingItemIndex)
            if (matchingItemIndex !== -1) {

                const updatedItems = [...items];
                updatedItems[matchingItemIndex] = {
                    ...updatedItems[matchingItemIndex],
                    backgroundColor: "",
                    status: "sku-not-matched",
                };

                console.log('updatedItems', updatedItems[matchingItemIndex])
                setItems(updatedItems);
                await handleClick1(updatedItems[matchingItemIndex], enteredSku, 0, 'global');
                setTimeout(() => {
                    scrollToItem(enteredSku);
                }, 300);
            } else {

                handleScanFailure(enteredSku);
            }

        }

        setTimeout(() => {
            setSkuInput('');
        }, 2000);
    };

    const scrollToItem = (sku: string) => {
        const element = document.getElementById(`sku-${sku}`);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };


    const handleScanFailure = (scannedSku: string) => {
        setScannedItems(prev => [...prev, { sku: scannedSku, status: 'Fail' }]);
    };

    const handlePrintLabel = async () => {
        console.log(city)
        let shipmentCompany = city === 'Riyadh' ? 'jena' : 'aramex'
        try {
            const response = await assignOrderToShipment(order_no,shipmentCompany);
            if (response.status) {
                const printUrl = response.data.label_print;
                window.open(printUrl, "_blank");

            } else if(city !== 'Riyadh' && !response.status){
                window.alert("Please check label issue with team");
            } else {
                window.alert("This order is already printed!!!");
            }
        } catch (error) {
            console.error("Error printing label:", error);
        }
    }

    // const printLabelDirectly = async (printUrl: any) => {
    //     try {
    //         const response = await fetch(printUrl);
    //         const blob = await response.blob();
    //         const url = URL.createObjectURL(blob);

    //         const printWindow = window.open(url, "_blank");
    //         if (printWindow) {
    //             printWindow.onload = () => {
    //                 printWindow.print();
    //                 setTimeout(() => printWindow.close(), 500);
    //             };
    //         }
    //     } catch (error) {
    //         console.error("Error printing label:", error);
    //     }
    // };


    const items = item?.length === 0 ? [] : item
    const order_stage_status = items?.map((item) => item.order_stage)[0]?.toString() || '';
    const disabled = updated_items?.length === 0 || order_stage_status === 'completed from quality' ? false : trackCompleteditems(warehouse)


    return (
        <div>
            {items && items.length > 0 && <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleControlDialog}
                aria-labelledby="responsive-dialog-title"
                scroll="paper"
                sx={{
                    '& .MuiDialog-paper': {
                        width: isLargeScreen ? '90%' : '100%',
                        maxWidth: 'none',
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {"No Of Items"} - {items.length}
                        </Grid>
                        <Grid item xs={1}>
                            {current_order_status === 7 ? <div style={{ backgroundColor: "red", color: "white", padding: '3px', }}>Cancelled</div> : ""}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Container>
                        {/* 🔹 Show Search Bar ONLY on Large Screens */}
                        {isLargeScreen && (
                            <TextField
                                label="Scan or Enter Barcode"
                                variant="outlined"
                                fullWidth
                                value={skuInput}
                                onChange={handleSkuChange}
                                sx={{ marginBottom: 3 }}
                            />
                        )}

                        <Grid container spacing={2}>
                            {isLargeScreen && (
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Order Items</Typography>
                                    <TableContainer component={Paper} sx={{ maxHeight: 900, overflowY: "auto" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>SKU</strong></TableCell>
                                                    <TableCell><strong>Status</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        id={`sku-${item.sku}`}
                                                        style={{ backgroundColor: item.status === "found" ? "#ACE1AF" : item.status === "Not Found" ? "#F08080" : "transparent" }}

                                                    >
                                                        <TableCell>{item.sku}</TableCell>
                                                        <TableCell>
                                                            {item.status === "found" ? "✅ Found" : item.status === "Not Found" ? "❌ Not Found" : "⌛ Pending"}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )}

                            {/* 🔹 Right Side: Items Grid (ALWAYS Visible) */}
                            <Grid item xs={12} marginTop={4} md={isLargeScreen ? 8 : 12}>
                                {/* <Typography variant="h6">Item List</Typography> */}
                                <Grid container spacing={2}>
                                    {items && items.length > 0 &&
                                        <Grid container justifyContent="center" alignItems="center" style={{ gap: 70 }}>
                                            <Grid container style={matches ? { columnGap: 10, rowGap: 20, display: 'flex', justifyContent: 'center' } : { gap: 22 }}>
                                                {items && items?.map((value: any, index: number) => {
                                                    return <ItemGrid key={index} value={{ ...value, background: value.background }} handleClick={handleClick1} handlePrintLabel={handlePrintLabel} handleChange={handleChange} warehouse1={warehouse} />
                                                })}
                                            </Grid>
                                            {/* <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button> */}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleControlDialog}>
                        Close
                    </Button>
                    {(isLargeScreen && user_id == '4') && <Button onClick={handlePrintLabel} >
                        Print Label
                    </Button>}
                    {(isLargeScreen && user_id == '4') && <Button onClick={handleSaveChanges} disabled={!disabled} autoFocus>
                        {order_stage_status === 'completed from quality' ? "Already Completed" : "Completed"}
                    </Button>}
                </DialogActions>
            </Dialog>}
        </div>
    );

}