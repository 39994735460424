import React, { useEffect, useState } from "react"
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Outlet } from 'react-router-dom'
import Barcode from 'react-barcode'
import FullScreenDialog from "../component/dialoge.tsx";
import { useNavigate } from "react-router-dom";
import { getcurrentStage } from "../utility/utility.tsx";
import { BasketType } from "../typs.ts";
import { getbasket, getOpenBaskets, getCompletedBaskets } from "../APIs/APIs.tsx";
import { InputType } from "../component/input/input.tsx";
import { Loading } from "../component/loading/loading.tsx";
import { handlePrintLabel } from '../utility/utility.tsx'
import ProgressBar from "../component/progressBar/progress.tsx";

type Dialog = {
    id: number,
    order_no: string,
    order_id: number,
    open: boolean
}
interface BasketProps {
    tabName: string;
}

export function Basket({ tabName }: BasketProps) {
    const [basket, setBasekets] = useState<BasketType[] | undefined>()
    const [dialog, setDialog] = useState<Dialog | undefined>()
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const [basketStatusState, setBasketStatus] = useState<string>("")
    const [checked, setChecked] = useState<boolean>(true)
    const navigate = useNavigate()
    const isLoggedIn = localStorage.getItem('id') ? true : false
    const result = getcurrentStage()
    const user_id = localStorage.getItem('id')
    const basketStatus = [
        {
            RealValue: "center",
            Value: "center",
        },
        {
            RealValue: "store",
            Value: "store",
        },
        {
            RealValue: "procurement",
            Value: "procurement",
        },
        {
            RealValue: "completed from center",
            Value: "quality",
        },
        {
            RealValue: "completed from store",
            Value: "S => W",
        },
        {
            RealValue: "completed from procurement",
            Value: "P => W",
        },
    ]

    useEffect(() => {
        if (!isLoggedIn) {
            return navigate('/login')
        }
        if (user_id) {
            const user_idNum = Number(user_id)
            const stage = result.toString()
            getbasketfun(user_idNum)
        }
    }, [])

    const getbasketfun = async (user_id: number) => {
        let data: BasketType[] | undefined;
        if (tabName === 'baskets') {
            data = await getbasket(user_id);
        } else if (tabName === 'completed baskets') {
            data = await getCompletedBaskets(user_id);
        } else {
            data = await getOpenBaskets();
        }
        setBasekets(data);
    };

    const handleClick = (id?: number, order_no?: string, order_id?: number) => {
        const d = !dialog?.open
        const dialoge = {
            id: id !== undefined ? id : 0,
            open: d,
            order_no: order_no !== undefined ? order_no : "",
            order_id: order_id
        }
        setDialog(dialoge)
    }
    const handleSearchbybasket = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(event.target.value)
    }
    const handleSwitch = () => {
        setChecked(!checked)
    }

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBasketStatus(event.target.value)
    }

    function determineStatus(value: BasketType) {
        if (value.order_stage === 'completed from center') {
            return 'quality';
        } else if (value.order_stage === 'completed from store') {
            return 'S => C';
        }
        else if (value.order_stage === "completed from procurement") {
            return 'P => C'
        }
        else if (value.stage === 'quality') {
            return 'Not Found';
        }
        else return value.order_stage === 'procurement' ? 'proc' : value.order_stage === null ? value.stage : value.order_stage;

    }

    const baskets = (basket && basket?.length > 0) ? basket.filter((item) => {
        if (checked) {
            return searchQuery?.toLowerCase() === ''
                ? basketStatusState !== '' && basketStatusState ? item.order_stage && item.order_stage.includes(basketStatusState) : basket
                : searchQuery && item.id.toString().toLowerCase().includes(searchQuery);
        } else {
            return searchQuery?.toLowerCase() === ''
                ? basketStatusState !== '' && basketStatusState ? item.order_stage && item.order_stage.includes(basketStatusState) : basket
                : searchQuery && item.order_no.toLowerCase().includes(searchQuery);
        }
    }) : [];

    const options = { timeZone: 'Asia/Riyadh' };

    return (
        <>
            <InputType checked={checked} handleSwitch={handleSwitch} handleSearchbybasket={handleSearchbybasket} basketStatus={basketStatus} handleSelect={handleSelect} basketStatusState={basketStatusState} />
            <Typography sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px', flexFlow: 'row' }}>Total Baskets: {basket?.length}</Typography>
            <Grid container justifyContent="center" alignItems="center" sx={{ gap: 2 }}>
                {!basket ? <Loading /> : baskets && baskets.map((value, index) => {
                    const formattedDate = new Date(value.order_date).toLocaleString('en-EG');

                    return <Card key={index} sx={{ maxWidth: 275, position: "relative", textAlign: 'center' }}>
                        <ProgressBar
                            items_count={value.items_count}
                            center_feedback_yes_count={value.center_feedback_yes_count}
                            store_feedback_yes_count={value.store_feedback_yes_count}
                            procurement_feedback_yes_count={value.procumenet_feedback_yes_count}
                        />
                        {value.order_date && value.order_date?.length !== 0 ? <span style={{ display: 'block', backgroundColor: '#ACE1AF', color: 'white' }} >{formattedDate}</span> : ''}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', position: "relative" }}>
                            <Box sx={{ background: value.has_completed_items === 0 ? 'black' : '#1976d2', color: 'white', width: 90, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {value.order_stage === 'completed from quality' ? 'completed' : determineStatus(value)}
                            </Box>
                            <Box sx={{ background: 'red', color: 'white', width: 60, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {value.id}
                            </Box>
                        </Box>
                        <CardContent sx={{ paddingTop: 3 }}>
                            <Typography sx={{ fontSize: 14, lineHeight: '1rem', fontWeight: 'bold', textAlign: 'left' }} gutterBottom>
                                Name: {value.name}
                            </Typography>

                            {value.city_title && value.city_title?.length !== 0 ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 1, border: '1px solid #E5E4E2	' }}>
                                    <Typography sx={{ fontSize: 14, lineHeight: '1rem', fontWeight: 'bold' }} gutterBottom>
                                        City: {value.city_title}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, lineHeight: '1rem', fontWeight: 'bold' }} gutterBottom>
                                        User Phone:  {value.user_phone_number}
                                    </Typography>
                                </Box>
                                : ''}
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <div>
                                    <Barcode value={value.order_no} />
                                </div>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button fullWidth variant="contained" color="secondary" size="small" onClick={() => handlePrintLabel(value.order_no, 'basket')}>Print</Button>
                            <Button fullWidth variant="contained" size="small" onClick={() => handleClick(value.id, value.order_no, value.order_id)}>Edit</Button>
                        </CardActions>
                    </Card>
                })}
            </Grid>
            {dialog && <FullScreenDialog id={dialog.id} open={dialog.open} order_no={dialog.order_no} order_id={dialog.order_id} handleClick={handleClick}></FullScreenDialog>}
            <Outlet />
        </>
    )
}
