import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Basket } from '../../pages/basket.tsx';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { getcurrentStage } from '../../utility/utility.tsx';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}




function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function SimpleTab() {
    const [value, setValue] = React.useState(0);
    const stage = getcurrentStage()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth='lg' style={{ paddingTop: 15 }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Baskets" {...a11yProps(0)} />
                        {stage === 'quality' ? <Tab label="Compledted Baskets" {...a11yProps(1)} /> : <></>}
                        {stage === 'center' && isLargeScreen && <Tab label="Open Baskets" {...a11yProps(2)} />}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Basket tabName="baskets" />
                </CustomTabPanel>


                <CustomTabPanel value={value} index={1}>
                    <Basket tabName="completed baskets" />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Basket tabName="open baskets" />
                </CustomTabPanel>
            </Box>
        </Container>

    );
}
