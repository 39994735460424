import axios from "axios";
import { getcurrentStage, getToken } from "../utility/utility.tsx";
import { Item, BasketType } from '../typs';
const api = "https://www.bodyplus-portal.com/api"
const secretKey = "1IqgdHEaIqgdUWdWfjh1KXnDvfwBjKXnDDyZ2tUWdWPd"
const token = getToken()

const handleUnauthorized = () => {
    window.location.href = '/login';
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 403) {
            handleUnauthorized();
        }
        return Promise.reject(error);
    }
);

export const login = async (pincode: string) => {
    console.log("login")
    console.log(secretKey)
    const response = await axios({
        method: 'POST',
        url: `${api}/login`,
        headers: { 'Content-Type': 'application/json' },
        data: {
            pincode: pincode,
	        secretKey:"1IqgdHEaIqgdUWdWfjh1KXnDvfwBjKXnDDyZ2tUWdWPd"
        }
    })
    console.log(response.data)
    const data = response.data;
    if (data.status)
        return data;
    else
        alert(data.msg);
}
export const getReports = async (report_type: number, date_range: []) => {
    console.log({
        report_type,
        date_range
    })
    const bodyData = {
        report_type,
        date_range
    }
    try {
        const response = await axios({
            method: 'POST',
            url: `${api}/reports`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        if (response.status === 200) {
            return response.data
        }
        return null;
    } catch (error) {
        return error
    }
}

export const importFileAPI = async (table:string, data:[],secretKey:string) => {
    const bodyData = {
        table,
        data_needed_to_be_inserted: data,
        secretKey
    }
    try {
        const response = await axios({
            method: 'POST',
            url: `${api}/file-import`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        if (response.status === 200) {
            return response.data
        }
        return response;
    } catch (error) {
        return error
    }
}

export const importFileAPIToUpdate = async (table:string, data:[],secretKey:string) => {
    const bodyData = {
        table,
        data_to_be_updated: data,
        secretKey
    }
    try {
        const response = await axios({
            method: 'PATCH',
            url: `${api}/updateitembyimport`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        if (response.status === 200) {
            return response.data
        }
        return response;
    } catch (error) {
        return error
    }
}

export const updateItem = async (id: number, toWhereToAnswer: string, state: string, user_id: string, stage: string, basket_id: number,UpdatefromWhere:string) => {
    try {
        const bodyData = {
            "item_id": id,
            "toWhereToAnswer": toWhereToAnswer,
            "answer": state,
            user_id,
            stage,
            basket_id,
            UpdatefromWhere
        }
        const response = await axios({
            method: 'PATCH',
            url: `${api}/update/item`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return null;
    } catch (error) {
        return error;
    }
};

export const updateWorkOrder = async (items?: Item[], work_order_id?: number, stage?: string) => {
    const current_stage = getcurrentStage();
    try {
        const bodyData = (current_stage === "center" || current_stage === "quality") ? { work_order_id, current_stage, stage } : { items, current_stage, stage };
        const response = await axios.patch(
            `${api}/update/work_order`,
            bodyData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        if (response.status === 200) {
            return response.data;

        }
    } catch (error) {
        console.error(error.message);
        return undefined
    }
};

export const insertToBasketStatus = async (items: Item) => {
    try {
        const bodyData = {
            "items": items
        }
        const response = await axios({
            method: 'POST',
            url: `${api}/add/basket_status`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }

        });
        if (response.status === 200)
            return "success";
        return undefined;
    } catch (error) {
        return null;
    }
}

export const updateBasket = async (basket_id: number, status: string) => {
    try {
        const bodyData = {
            "basket_id": basket_id,
            "status": status
        }
        const response = await axios({
            method: 'PATCH',
            url: `${api}/update/basket`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }

        });

        if (response.status === 200)
            return "success";
        return undefined;
    } catch (error) {
        return null;
    }
}

export const getBasaketItems = async (id: number, order_no: string): Promise<Item[] | undefined> => {

    try {
        const bodyData = {
            basket_id: id,
            order_no,
        };
        const response = await axios({
            method: 'POST',
            url: `${api}/baskets/items`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }
        });

        const data = response.data;
        if (data.status === "false") {
            return undefined;
        }

        const updatedDate = data.data && data.data.map((item: Item) => ({
            ...item,
            status: item.quality_feedback !== null
                ? (item.quality_feedback === "Yes" ? "found" : item.quality_feedback === "No" ? "Not Found" : "Pending")
                : item.feedback_status === "Yes" ? "found" : item.feedback_status === "No" ? "Not Found" : "Pending",
        }));

        return updatedDate;
    } catch (error) {
        return undefined;
    }
};

export const getbasket = async (user_id: number): Promise<BasketType[] | undefined> => {
    try {
        const bodyData =
        {
            "user_id": user_id,
            "stage": getcurrentStage()
        }

        const response = await axios({
            method: 'POST',
            url: `${api}/baskets`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }

        })

        const data = response.data
        if (data.status === "false")
            return undefined
        return data.data
    } catch (error) {
        return undefined;
    }
}

export const getOpenBaskets = async (): Promise<BasketType[] | undefined> => {
    try {
       
        const response = await axios({
            method: 'POST',
            url: `${api}/baskets`,
            headers: { 'Authorization': `Bearer ${token}` }

        })

        const data = response.data
        if (data.status === "false")
            return undefined
        return data.data
    } catch (error) {
        return undefined;
    }
}

export const getCompletedBaskets = async (user_id: number): Promise<BasketType[] | undefined> => {
    try {
        const bodyData =
        {
            "user_id": user_id,
            "stage": getcurrentStage()
        }

        const response = await axios({
            method: 'POST',
            url: `${api}/baskets/completed`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }

        })

        const data = response.data
        if (data.status === "false")
            return undefined
        return data.data
    } catch (error) {
        return undefined;
    }
}

export const updateOrder = async (order_ids: number[], stage: string, user_id: number) => {
    try {
        const bodyData = {
            "order_ids": order_ids,
            "stage": stage,
            "user_id": user_id
        }
        const response = await axios({
            method: 'PATCH',
            url: `${api}/update/order`,
            data: bodyData,
            headers: { 'Authorization': `Bearer ${token}` }

        })
        if (response.status === 200)
            return response?.msg || 'success';
        return response?.msg;
    } catch (error) {
        return 'something went wrong'
    }
}


export const checkOrderStatus = async (order_no: string) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${api}/order/status`,  // Ensure the correct API URL here
            data: { order_no },  // Use `params` instead of `data` for GET requests
            headers: { 'Authorization': `Bearer ${token}` }
        });

        // Axios automatically parses the response as JSON
        console.log(response)
        return response.data;  // You can directly access the response data here
    } catch (error) {
        console.error("Error fetching order status:", error);
        throw new Error("Failed to fetch order status");
    }
};

export const getCityForOrder = async (order_no: string) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${api}/order/get-city`, 
            data: { order_number:order_no },  
            headers: { 'Authorization': `Bearer ${token}` }
        });

        
        console.log(response)
        return response.data;  
    } catch (error) {
        console.error("Error fetching order status:", error);
        // throw new Error("Failed to city for order status:");
    }
};


export const assignOrderToShipment = async (order_no: string, shipmentCompany: string) => {
    console.log(shipmentCompany)
    try {
        const response = await axios({
            method: 'POST',
            url: `${api}/order/assign-order`,  
            data: { order_number:order_no, shipping_company:shipmentCompany },  
            headers: { 'Authorization': `Bearer ${token}` }
        });
 
        console.log(response)
        return response.data;  
    } catch (error) {
        console.error("Error fetching order status:", error);
        // throw new Error("Failed to assign order");
    }
};


